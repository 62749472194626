import React from "react"
import { Link } from "gatsby"
import { features } from "../constants"
import styles, { layout } from "../styles/style"
import Button from "./button"

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`feedback-card flex flex-row p-6 rounded-[20px] bg-dark-blue-gradient ${
      index !== features.length - 1 ? "mb-6" : "mb-0"
    } feature-card`}
  >
    <div
      className={`w-[64px] h-[64px] flex rounded-full ${styles.flexCenter} bg-secondary`}
    >
      <img
        src={icon}
        alt="star"
        className="w-[50%] h-[50%] object-contain fill-[#243c5a] "
        style={{ fill: "#FFFFFF" }}
      />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-green-gradient text-[18px] leading-[33.4px] mb-2">
        <span> {title}</span>
      </h4>
      <p className="font-poppins font-normal text-white text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
)

const AboutUs = () => (
  <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
    <div className={`${styles.boxWidth}`}>
      <section id="about-us" className={layout.section}>
        <div className={layout.sectionInfo}>
          <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-8">
            <span className="text-green-gradient">H&S Pepper Co</span>
          </h2>
          <p
            className={`${styles.paragraph2} lg:max-w-[470px] max-w-[100%] mt-5`}
          >
            We are a small, family run business, where we grow our chillis,
            pesticide free. Saucing a wide range and collections of hot pepper
            sauces, chilli pickles, and chilli salts, using our traditional
            homemade family recipe.
          </p>
          <p
            className={`${styles.paragraph2} lg:max-w-[470px] max-w-[100%] mt-5`}
          >
            Every care is taken to protect our chillies during the growing
            season and only the ripest chillis are handpicked off the vine,
            before going into any of our products
          </p>

          <Link to="/products" alt="test">
            <Button label="View Products" styles={`mt-6`} />
          </Link>
        </div>

        <div className={`${layout.sectionImg} flex-col `}>
          {features.map((feature, index) => (
            <FeatureCard key={feature.id} {...feature} index={index} />
          ))}
        </div>
      </section>
    </div>
  </div>
)

export default AboutUs
