import React from "react"
import ArticleCard from "../article-card"

const BlogItem = ({ alt, image, slug, title, author, excerpt }) => {
  return (
    <>
      <div className="rounded-lg overflow-hidden shadow-md hover:opacity-50 ">
        <ArticleCard
          alt={alt}
          image={image}
          slug={slug}
          title={title}
          author={author}
          excerpt={excerpt}
        />
      </div>
    </>
  )
}

export default BlogItem
