import React, { useState } from "react"
import { useForm } from "@formspree/react"
import validate from "../constants/validate"
import styles from "../styles/style"

export default function ContactForm() {
  const [state, handleSubmit] = useForm(process.env.GATSBY_CONTACT_FORM)

  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })

  const [username, setUsername] = useState({
    name: "",
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
    console.log("values: ", values)
  }

  const manageSubmit = (e) => {
    e.preventDefault()

    // other logic or error checking logic here
    setErrors(validate(values))
    //console.log("errors: ", errors)
    //console.log("values: ", values)

    if (
      values.name.length > 0 &&
      values.subject.length > 0 &&
      values.email.length > 0 &&
      values.message.length > 0
    ) {
      //console.log("values in if statement: ", values)
      setUsername(values.name)
      handleSubmit(e)
      resetForm()
      //console.log("Submitted successfully")
    }
  }

  const resetForm = () => {
    values.name = ""
    values.email = ""
    values.subject = ""
    values.message = ""
  }

  return (
    <div className="w-full px-4 lg:w-1/2 xl:w-5/12 mt-8 mb-10">
      <div className="relative rounded-lg bg-white p-8 shadow-lg sm:p-12">
        <form onSubmit={manageSubmit} noValidate>
          <div className="mb-6">
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Enter your name..."
              onChange={handleChange}
              value={values.name}
              className="text-body-color border-[f0f0f0] focus:border-green-500 focus:ring-2 focus:ring-green-200 w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
            />
            {errors.name && (
              <div
                className="flex p-4 mt-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="mb-6">
            <input
              id="subject"
              type="text"
              name="subject"
              onChange={handleChange}
              value={values.subject}
              placeholder="Enter a subject..."
              className="text-body-color border-[f0f0f0] focus:border-green-500 focus:ring-2 focus:ring-green-200 w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
            />
            {errors.subject && (
              <div
                className="flex p-4 mt-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">{errors.subject}</span>
                </div>
              </div>
            )}
          </div>
          <div className="mb-6">
            <input
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              placeholder="Enter your email..."
              className="text-body-color border-[f0f0f0] focus:border-green-500 focus:ring-2 focus:ring-green-200 w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
            />
            {errors.email && (
              <div
                className="flex p-4 mt-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className="mb-6">
            <textarea
              id="message"
              type="text"
              name="message"
              rows="5"
              onChange={handleChange}
              value={values.message}
              placeholder="Enter your message, 500 characters max..."
              className="text-body-color border-[f0f0f0] focus:border-green-500 focus:ring-2 focus:ring-green-200 w-full resize-none rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
            />
            {errors.message && (
              <div
                className="flex p-4 mt-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">{errors.message}</span>
                </div>
              </div>
            )}
          </div>
          <div>
            <button
              type="submit"
              className={`py-2 px-4 font-poppins font-medium text-[18px] text-primary bg-black-gradient-3  rounded-[10px] outline-none ${styles} hover:text-secondary w-full`}
              disabled={state.submitting}
            >
              Submit your message
            </button>
            {state.succeeded && (
              <div
                className="flex p-4 mb-4 mt-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">
                    Thank you {username}, your message has been sent
                    successfully.
                  </span>
                </div>
              </div>
            )}
          </div>
        </form>
        <div>
          <span className="absolute -top-10 -right-9 z-[-1]">
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                fill="#00AB08"
              />
            </svg>
          </span>
          <span className="absolute -right-10 top-[90px] z-[-1]">
            <svg
              width="34"
              height="134"
              viewBox="0 0 34 134"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="31.9993"
                cy="132"
                r="1.66667"
                transform="rotate(180 31.9993 132)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 31.9993 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 31.9993 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="88"
                r="1.66667"
                transform="rotate(180 31.9993 88)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 31.9993 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="45"
                r="1.66667"
                transform="rotate(180 31.9993 45)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="16"
                r="1.66667"
                transform="rotate(180 31.9993 16)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="59"
                r="1.66667"
                transform="rotate(180 31.9993 59)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 31.9993 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 31.9993 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="132"
                r="1.66667"
                transform="rotate(180 17.3333 132)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 17.3333 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 17.3333 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="88"
                r="1.66667"
                transform="rotate(180 17.3333 88)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 17.3333 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="45"
                r="1.66667"
                transform="rotate(180 17.3333 45)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="16"
                r="1.66667"
                transform="rotate(180 17.3333 16)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="59"
                r="1.66667"
                transform="rotate(180 17.3333 59)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 17.3333 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 17.3333 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="132"
                r="1.66667"
                transform="rotate(180 2.66536 132)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 2.66536 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 2.66536 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="88"
                r="1.66667"
                transform="rotate(180 2.66536 88)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 2.66536 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="45"
                r="1.66667"
                transform="rotate(180 2.66536 45)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="16"
                r="1.66667"
                transform="rotate(180 2.66536 16)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="59"
                r="1.66667"
                transform="rotate(180 2.66536 59)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 2.66536 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 2.66536 1.66665)"
                fill="#00AB08"
              />
            </svg>
          </span>
          <span className="absolute -left-7 -bottom-7 z-[-1]">
            <svg
              width="107"
              height="134"
              viewBox="0 0 107 134"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="104.999"
                cy="132"
                r="1.66667"
                transform="rotate(180 104.999 132)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 104.999 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 104.999 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="88"
                r="1.66667"
                transform="rotate(180 104.999 88)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 104.999 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="45"
                r="1.66667"
                transform="rotate(180 104.999 45)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="16"
                r="1.66667"
                transform="rotate(180 104.999 16)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="59"
                r="1.66667"
                transform="rotate(180 104.999 59)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 104.999 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="104.999"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 104.999 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="132"
                r="1.66667"
                transform="rotate(180 90.3333 132)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 90.3333 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 90.3333 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="88"
                r="1.66667"
                transform="rotate(180 90.3333 88)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 90.3333 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="45"
                r="1.66667"
                transform="rotate(180 90.3333 45)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="16"
                r="1.66667"
                transform="rotate(180 90.3333 16)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="59"
                r="1.66667"
                transform="rotate(180 90.3333 59)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 90.3333 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="90.3333"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 90.3333 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="132"
                r="1.66667"
                transform="rotate(180 75.6654 132)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="132"
                r="1.66667"
                transform="rotate(180 31.9993 132)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 75.6654 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 31.9993 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 75.6654 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 31.9993 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="88"
                r="1.66667"
                transform="rotate(180 75.6654 88)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="88"
                r="1.66667"
                transform="rotate(180 31.9993 88)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 75.6654 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 31.9993 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="45"
                r="1.66667"
                transform="rotate(180 75.6654 45)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="45"
                r="1.66667"
                transform="rotate(180 31.9993 45)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="16"
                r="1.66667"
                transform="rotate(180 75.6654 16)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="16"
                r="1.66667"
                transform="rotate(180 31.9993 16)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="59"
                r="1.66667"
                transform="rotate(180 75.6654 59)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="59"
                r="1.66667"
                transform="rotate(180 31.9993 59)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 75.6654 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 31.9993 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="75.6654"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 75.6654 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="31.9993"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 31.9993 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="132"
                r="1.66667"
                transform="rotate(180 60.9993 132)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="132"
                r="1.66667"
                transform="rotate(180 17.3333 132)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 60.9993 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 17.3333 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 60.9993 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 17.3333 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="88"
                r="1.66667"
                transform="rotate(180 60.9993 88)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="88"
                r="1.66667"
                transform="rotate(180 17.3333 88)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 60.9993 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 17.3333 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="45"
                r="1.66667"
                transform="rotate(180 60.9993 45)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="45"
                r="1.66667"
                transform="rotate(180 17.3333 45)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="16"
                r="1.66667"
                transform="rotate(180 60.9993 16)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="16"
                r="1.66667"
                transform="rotate(180 17.3333 16)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="59"
                r="1.66667"
                transform="rotate(180 60.9993 59)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="59"
                r="1.66667"
                transform="rotate(180 17.3333 59)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 60.9993 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 17.3333 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="60.9993"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 60.9993 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="17.3333"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 17.3333 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="132"
                r="1.66667"
                transform="rotate(180 46.3333 132)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="132"
                r="1.66667"
                transform="rotate(180 2.66536 132)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 46.3333 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 2.66536 117.333)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 46.3333 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 2.66536 102.667)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="88"
                r="1.66667"
                transform="rotate(180 46.3333 88)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="88"
                r="1.66667"
                transform="rotate(180 2.66536 88)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 46.3333 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 2.66536 73.3333)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="45"
                r="1.66667"
                transform="rotate(180 46.3333 45)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="45"
                r="1.66667"
                transform="rotate(180 2.66536 45)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="16"
                r="1.66667"
                transform="rotate(180 46.3333 16)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="16"
                r="1.66667"
                transform="rotate(180 2.66536 16)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="59"
                r="1.66667"
                transform="rotate(180 46.3333 59)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="59"
                r="1.66667"
                transform="rotate(180 2.66536 59)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 46.3333 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="30.6666"
                r="1.66667"
                transform="rotate(180 2.66536 30.6666)"
                fill="#00AB08"
              />
              <circle
                cx="46.3333"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 46.3333 1.66665)"
                fill="#00AB08"
              />
              <circle
                cx="2.66536"
                cy="1.66665"
                r="1.66667"
                transform="rotate(180 2.66536 1.66665)"
                fill="#00AB08"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  )
}
