export default function validate(values) {
  let errors = {}

  console.log("Validate: values ", values)

  if (!values.name) {
    errors.name = "Your name is required"
  }
  // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
  //   errors.name = 'Enter a valid name';
  // }

  if (!values.subject) {
    errors.subject = "A subject is required"
  } else if (values.subject.length > 50) {
    errors.subject = "subject cant be more than 50 characters"
  }

  if (!values.email) {
    errors.email = "Your email address is required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid"
  }

  if (!values.message) {
    errors.message = "Your message is required"
  } else if (values.message.length > 500) {
    errors.message = "Message cant be more than 500 characters"
  }

  return errors
}
