import React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import { card } from "../images";
import styles, { layout } from "../styles/style"
import Button from "./button"

const AdvertImageLeft = () => {
  return (
    <div className={`${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section className={`${layout.sectionReverse} relative`}>
          <div className="absolute z-[0] w-[30%] h-[30%] -left-[40%] rounded-full blue__gradient bottom-20" />
          <div className={`${layout.sectionImgReverse}`}>
            <StaticImage
              src="../images/emperor-nero-web-tile.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              alt="roof with 4 Velux windows"
              aspectRatio={1.3}
              className="w-[100%] h-[100%] relative z-[5]"
              style={{ borderRadius: "10px" }}
            />

            {/* gradient start */}
            <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient" />
            <div className="absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient" />
            {/* gradient end */}
          </div>

          <div className={layout.sectionInfo}>
            <h2 className="font-poppins font-bold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full">
              <span className="text-green-gradient">Partnerships</span>
              <br className="xx:block hidden" />{" "}
              <span className="text-green-gradient">& stockist</span>{" "}
            </h2>
            <p
              className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%] mt-5`}
            >
              We at H&S peppers welcome local businesses and partners who wish
              to become stockiest or need any information regarding our product,
              to please get in touch
            </p>
            <p
              className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%] mt-5`}
            >
              You can use the contact form to get in touch, please remember to
              add stockiest in the subject line, or you can click the button
              below to email us now!
            </p>

            <div className="w-full text-left md:text-right ">
              <a
                href="mailto:admin@hspepper.co.uk?subject=Stockist Information"
                alt="stockist email link"
                className="text-right"
              >
                <Button label="Email Us!" styles={`mt-6`} />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AdvertImageLeft
