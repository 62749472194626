import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BlogItem from "./blog-Item"
import styles from "../../styles/style"

const Blog = () => {
  const articles = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
            tag
            author
            slug
            excerpt
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 1.5
                )
              }
            }
          }
          html
        }
      }
    }
  `)

  const allArticles = articles.allMarkdownRemark.nodes.map((item, index) => (
    <BlogItem
      key={index}
      image={item.frontmatter.image.childImageSharp.gatsbyImageData}
      slug={item.frontmatter.slug}
      author={item.frontmatter.author}
      alt={item.frontmatter.title}
      title={item.frontmatter.title}
      excerpt={item.frontmatter.excerpt}
    />
  ))
  return (
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section id="articles" className={`${styles.marginYSmall} `}>
          <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-8">
            <span className="text-green-gradient">Featured Articles</span>
          </h2>

          <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            {allArticles}
          </div>
        </section>
      </div>
    </div>
  )
}

export default Blog
