import React from "react"
import styles from "../styles/style"
import HomeIcon from "../icons/home-icon-silhouette.svg"
//import PhoneIcon from "../icons/phone-icon.svg"
import EmailIcon from "../icons/emails-icon.svg"
import Shield from "../icons/Shield.svg"
import ContactForm from "./contact-form"

const ContactUs = () => {
  return (
    <div
      id="contact-us"
      className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <section className="relative z-10 ml-2 mr-6  pb-[40px] mt-10">
          <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap lg:justify-between">
              <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
                <div className="max-w-[570px] ">
                  <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px]  w-full">
                    <span className="text-green-gradient">Get in touch</span>
                    <br className="xx:block hidden" />{" "}
                    <span className="text-green-gradient">with us</span>{" "}
                  </h2>

                  <p className={`${styles.paragraph2} max-w-[670px] mb-6`}>
                    If you are a customer and you want to know anything about
                    our products, or you want to become a partner or a stockiest
                    of H&S Pepper Co, then please, fill out the contact form
                    with the necessary details, or use one of the email links
                    below, and we will get back to you.
                  </p>
                  <div className="flex w-full max-w-[370px]">
                    <div className="bg-primary y mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]">
                      <HomeIcon
                        className="icon-color grow"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                    <div className="w-full">
                      <h4 className=" mb-1 text-xl font-bold">
                        <span className="text-green-gradient">
                          Our Location
                        </span>
                      </h4>
                      <p className={`${styles.paragraph2} max-w-[670px] mb-6`}>
                        Taplow windsor
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full max-w-[470px]">
                    <div className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]">
                      <EmailIcon
                        className="icon-color grow"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                    <div className="w-full">
                      <h4 className=" mb-1 text-xl font-bold">
                        <span className="text-green-gradient">
                          General Information
                        </span>
                      </h4>
                      <p className={`${styles.paragraph2} max-w-[770px] mb-6`}>
                        <a href="mailto:info@hspeppers.co.uk">
                          info@hspeppers.co.uk
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full max-w-[470px]">
                    <div className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]">
                      <Shield
                        className="icon-color grow"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                    <div className="w-full">
                      <h4 className=" mb-1 text-xl font-bold">
                        <span className="text-green-gradient">
                          Stockists & Partners
                        </span>
                      </h4>
                      <p className={`${styles.paragraph2} max-w-[770px] mb-6`}>
                        <a href="mailto:info@hspeppers.co.uk">
                          admin@hspeppers.co.uk
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ContactUs
