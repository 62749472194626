import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleCard = ({ alt, image, slug, title, author, excerpt }) => {
  //console.log("alt: ", alt)
  return (
    <Link to={`/${slug}`}>
      <div className="relative overflow-hidden ">
        <GatsbyImage image={image} alt={alt} className="" />
      </div>
      <div className="relative ">
        <div className="py-4 px-4">
          <h3 className="font-semibold capitalize text-[20px] mb-2 mt-2">
            <span className="text-orange-grad">{title}</span>
          </h3>

          {/* <div className="text-gray-600 text-sm font-medium flex mb-4 mt-2">
            <p>Provided by&nbsp;</p>
            <p className="hover:text-black transition duration-300 ease-in-out">
              Security Staffing Solutions
            </p>
          </div> */}

          <p className="font-poppins font-normal dimGrey text-[14px] leading-[22.8px]">
            {excerpt}
          </p>

          <footer className="flex items-center justify-between leading-none mt-4">
            <div className="flex items-center no-underline  text-black">
              <img
                alt="Placeholder"
                className="block rounded-full"
                src="https://picsum.photos/32/32/?random"
              />
              <p className="ml-2 text-sm invisible xx:visible">{author}</p>
            </div>
            <div className="flex justify-between items-center no-underline text-grey-darker hover:text-red-dark">
              <p className="mr-4">Read more</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.125"
                height="13.358"
                viewBox="0 0 14.125 13.358"
              >
                <g transform="translate(-3 -3.293)">
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M14.189,10.739H3V9.2H14.189L9.361,4.378l1.085-1.085,6.679,6.679-6.679,6.679L9.361,15.566Z"
                    fill="#1d1d1d"
                    fillRule="evenodd"
                  ></path>
                </g>
              </svg>
            </div>
          </footer>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard
