import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import { card } from "../images";
import styles, { layout } from "../styles/style"
import Button from "./button"

const AdvertImageRight = () => {
  return (
    <div className={`bg-primary ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section className={layout.section}>
          <div className={layout.sectionInfo}>
            <h2 className="font-poppins font-bold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full">
              <span className="text-green-gradient">Super Nero</span>
              <br className="xx:block hidden" />{" "}
              <span className="text-green-gradient">Latest Addition!</span>{" "}
            </h2>
            <p
              className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%] mt-5`}
            >
              H&S Pepper co always looking to improve and update our range of
              products. Super Nero is the latest adition to the habanero
              collection, strictly for the CONNOISSEUR
            </p>
            <p
              className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%] mt-5`}
            >
              Super Nero is a solar inspiration! His heroic Big Sun Habanero
              chilli sauce will uplift you with its supernatural Level 4 powers
              at 350,000 SHU.
            </p>

            <Link
              to="/products/chilli-sauce/super-nero-big-sun-habanero-chilli-sauce/"
              alt="test"
            >
              <Button label="View Product" styles={`mt-6`} />
            </Link>
          </div>

          <div className={layout.sectionImg}>
            <StaticImage
              src="../images/super-nero-web-tile.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              alt="roof with 4 Velux windows"
              //transformOptions={{ fit: "cover", cropFocus: "attention" }}
              aspectRatio={1.3}
              className="w-[100%] h-[100%]"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </section>
      </div>
    </div>
  )
}

export default AdvertImageRight
