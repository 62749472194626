import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
//import { GatsbyImage } from "gatsby-plugin-image"

//import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "../components/layout"
//import { ProductListing } from "../components/product-listing"

//import {
//container,
//intro,
// callOut,
// callToAction,
// deployButton,
//} from "./index.module.css"

import styles from "../styles/style"
import AdvertImageRight from "../components/advert-image-right"
import AdvertImageLeft from "../components/advert-image-left"
import Testimonials from "../components/Testimonials"
import FeaturedProducts from "../components/featured-products"
import ContactUs from "../components/contactUs"
//import FeaturedArticles from "../components/featured-articles"
import AboutUs from "../components/about-us"
import Blog from "../components/blog"
//import FoodHygieneRatingAdvert from "../components/food-hygiene-rating-advert"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "home-page" }) {
      products {
        ...ProductCard
        description
      }
    }
  }
`

export default function IndexPage({ data }) {
  //console.log("collection: ", data?.shopifyCollection?.products)
  return (
    <Layout>
      <div
        id="home"
        className={`sticky z-10 ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <div className="flex flex-wrap gap-2 mb-30px">
            <div className="basis-full rounded-xl  bg-black text-center md:basis-[30%] flex bg-cover bg-center flex-1 h-[300px] bg-[url('https://cdn.shopify.com/s/files/1/0552/2751/5031/collections/big-sun-scotch-bonnet-v1_1280x1280_crop_center.jpg?v=1673273930')]">
              <Link
                to={`/search?t=${encodeURIComponent("Scotch Bonnet")}`}
                className="w-full"
              >
                <div className="m-auto bg-black opacity-60 p-3 text-white font-bold text-2xl uppercase rounded-xl object-cover h-full w-full bg-cover bg-center flex items-center justify-center flex-col">
                  <span className="text-white">Family Collection</span>
                  <br />
                  <span>For everyone!</span>
                </div>
              </Link>
            </div>

            <div className="basis-full rounded-xl  bg-black text-center  md:basis-[30%] flex bg-cover bg-center flex-1 h-[300px] bg-[url('https://cdn.shopify.com/s/files/1/0552/2751/5031/collections/orange-habanero_1536x2048_crop_center.jpg?v=1618407384')]">
              <Link
                to={`/search?t=${encodeURIComponent("Habanero")}`}
                className="w-full"
              >
                <div className="m-auto bg-black opacity-60 p-3 text-white font-bold text-2xl uppercase rounded-xl object-cover h-full w-full bg-cover bg-center flex items-center justify-center flex-col">
                  <span>Habanero Collection</span>
                  <br />
                  <span>For The CONNOISSEUR!</span>
                </div>
              </Link>
            </div>
            <div className="basis-full rounded-xl  bg-black text-center  md:basis-[30%] flex bg-cover bg-center flex-1 h-[300px] bg-[url('https://cdn.shopify.com/s/files/1/0552/2751/5031/collections/trinidad-scorpion_2048x1536_crop_center.jpg?v=1673273457')]">
              <Link
                to={`/search?t=${encodeURIComponent("Top Shelf")}`}
                className="w-full"
              >
                <div className="m-auto bg-black opacity-60 p-3 text-white font-bold text-2xl uppercase rounded-xl object-cover h-full w-full bg-cover bg-center flex items-center justify-center  flex-col">
                  <span>Top Shelf Collection</span>
                  <br />
                  <span>For the MIGHTY!</span>
                </div>
              </Link>
            </div>
            <div className="basis-full rounded-xl  bg-black text-center  md:basis-[30%] flex bg-cover bg-center flex-1 h-[300px] bg-[url('https://cdn.shopify.com/s/files/1/0552/2751/5031/collections/hero-plant-v1-100_2396x600_crop_center.jpg?v=1622478344')]">
              <Link
                to={`/search?t=${encodeURIComponent("All Sauces")}`}
                className="w-full"
              >
                <div className="m-auto bg-black opacity-60 p-3 text-white font-bold text-2xl uppercase rounded-xl object-cover h-full w-full bg-cover bg-center flex items-center justify-center flex-col">
                  <span>All Sauces</span>
                  <br />
                  <span>Find Your SAUCESPECTS</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <AboutUs />

      <div
        className={`bg-slate-100 mt-12 ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div id="trending" className={`${styles.boxWidth}`}>
          <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-8">
            <span className="text-green-gradient">Trending products</span>
          </h2>
          <FeaturedProducts products={data?.shopifyCollection?.products} />
        </div>
      </div>

      <div className={`mt-12 ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <AdvertImageRight />
        </div>
      </div>

      <div
        className={`bg-slate-100 mt-12 ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <AdvertImageLeft />
        </div>
      </div>

      {/* <div className={`mt-12 ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <FoodHygieneRatingAdvert />{" "}
        </div>
      </div> */}

      <div className={`mt-12 ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Testimonials />
        </div>
      </div>

      <Blog />

      <ContactUs />
    </Layout>
  )
}
